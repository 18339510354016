<template>
  <div>
    <div v-if="loading">
      <v-container>
        <v-skeleton-loader
          v-bind="attrs"
          type="table-heading, image, list-item-two-line, card"
        ></v-skeleton-loader>
      </v-container>
    </div>
    <div v-else-if="partner">
      <v-card rounded="0" flat min-height="100vh">
        <div style="height: 100vh; background-color: #F3F6FB;">
          <v-container fill-height>
            <div class="text-left">
              <v-img width="120" contain :src="partner.logo" />
            </div>
            <v-row no-gutters>
              <v-col cols="12" md="6" class="my-auto">
                <div>
                  <h2 class="text-uppercase" style="font-size: 60px;">
                    <strong> India's Fastest Bike Loan </strong>
                  </h2>
                  <div class="my-10">
                    <ul>
                      <li>
                        Zero Documentation Required
                      </li>
                      <li>Interest as low as 11.99%</li>
                      <li>Approval under 60 seconds</li>
                    </ul>
                  </div>
                </div>
                <v-card
                  @click="applyLoan()"
                  width="100%"
                  :color="
                    partner.themeColor ? partner.themeColor : 'deep-orange'
                  "
                  dark
                  max-width="600"
                  class="py-2 mx-auto-md-and-down"
                >
                  <v-container class="text-center">
                    <strong>Apply Loan</strong>
                  </v-container>
                </v-card>
              </v-col>
              <v-col cols="12" md="6" class="hidden-md-and-down">
                <v-img
                  contain
                  width="100%"
                  src="https://on-track-jarvis.s3.ap-south-1.amazonaws.com/72f5f454-f289-4f6b-aefe-1b56b5adc55f.png"
                ></v-img>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-card>

      <v-container class="text-center">
        <v-icon color="#FF5F1F">fas fa-horizontal-rule</v-icon>
        <h3>
          <strong> Bike Loan EMI Calculator</strong>
        </h3>
      </v-container>
      <v-container class="pb-10">
        <loan-calculator :theme="partner.themeColor" />
        <v-card flat color="grey lighten-3">
          <v-container class="my-10">
            <v-row class="">
              <v-col class="text-left" md="8" sm="6" xs="6" lg="8" xl="8">
                <h4 class="my-auto">
                  Ready to start your journey with us?
                </h4>
              </v-col>
              <v-col class="my-auto text-right ">
                <v-btn
                  :color="
                    partner.themeColor ? partner.themeColor : 'deep-orange'
                  "
                  dark
                  @click="applyLoan"
                >
                  Apply Now
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-container>

      <v-container>
        <h1>
          <strong>How it works?</strong>
        </h1>

        <div class="py-15">
          <div class="d-flex">
            <div class="draw">
              <v-avatar
                :color="partner.themeColor ? partner.themeColor : 'deep-orange'"
                size="30"
              >
                <span class="white--text">1</span>
              </v-avatar>
            </div>
            <div class="ml-4 pb-5">
              <h5><strong>Select your dream bike</strong></h5>
              <v-img
                width="250"
                contain
                src="../../../../assets/works.png"
              ></v-img>

              Choose between scooters and bikes, pick a brand and vehicle.
              Select the downpayment and loan tenure, we'll immediately show
              your EMI.
            </div>
          </div>
          <div class="d-flex">
            <div class="draw">
              <v-avatar
                :color="partner.themeColor ? partner.themeColor : 'deep-orange'"
                size="30"
              >
                <span class="white--text">2</span>
              </v-avatar>
            </div>
            <div class="ml-4 pb-5">
              <h5>
                <strong>Complete your KYC</strong>
              </h5>
              <v-img
                width="250"
                contain
                src="../../../../assets/works1.png"
              ></v-img>

              Fill up some basic details about yourself for us to check your
              eligibility. Please note that you'll not have to upload any
              documents at any step.
            </div>
          </div>
          <div class="d-flex">
            <div>
              <v-avatar
                :color="partner.themeColor ? partner.themeColor : 'deep-orange'"
                size="30"
              >
                <span class="white--text">3</span>
              </v-avatar>
            </div>
            <div class="ml-4 pb-5">
              <h5>
                <strong>Get your Loan Approved</strong>
              </h5>
              <v-img
                width="250"
                contain
                src="../../../../assets/works2.png"
              ></v-img>

              Verify via OTP and we'll approve your loan in less than 60
              seconds.
            </div>
          </div>
        </div>
      </v-container>

      <v-container>
        <h1>
          <strong> About {{ partner.partnerName }} </strong>
        </h1>
        <div class="py-15">
          {{ partner.description }}
        </div>
      </v-container>

      <v-container>
        <h1>
          <strong>Our Trusted Brands</strong>
        </h1>

        <v-row class="py-15">
          <v-col cols="3" class="my-auto" md="2">
            <v-img
              class="mx-auto"
              lazy-src="https://on-track-jarvis.s3.amazonaws.com/155490d0-c9e2-4c2d-9819-99d2f99ea6a4.svg"
              width="90%"
              src="https://on-track-jarvis.s3.amazonaws.com/155490d0-c9e2-4c2d-9819-99d2f99ea6a4.svg"
            />
          </v-col>
          <v-col cols="3" class="my-auto" md="2">
            <v-img
              class="mx-auto"
              lazy-src="https://on-track-jarvis.s3.ap-south-1.amazonaws.com/1bac00ee-612f-48a2-8b6b-775c223c1826.svg"
              width="90%"
              src="https://on-track-jarvis.s3.ap-south-1.amazonaws.com/1bac00ee-612f-48a2-8b6b-775c223c1826.svg"
            />
          </v-col>
          <v-col cols="3" class="my-auto" md="2">
            <v-img
              class="mx-auto"
              lazy-src="https://on-track-jarvis.s3.ap-south-1.amazonaws.com/1e68609e-0318-4b71-b1ad-da8270672c7d.svg"
              width="90%"
              src="https://on-track-jarvis.s3.ap-south-1.amazonaws.com/1e68609e-0318-4b71-b1ad-da8270672c7d.svg"
            />
          </v-col>
          <v-col cols="3" class="my-auto" md="2">
            <v-img
              class="mx-auto"
              lazy-src="https://on-track-jarvis.s3.ap-south-1.amazonaws.com/d05fca47-dc4d-4ed4-b2bb-71215b8ae236.svg"
              width="90%"
              src="https://on-track-jarvis.s3.ap-south-1.amazonaws.com/d05fca47-dc4d-4ed4-b2bb-71215b8ae236.svg"
            />
          </v-col>
          <v-col cols="3" class="my-auto" md="2">
            <v-img
              class="mx-auto"
              lazy-src="https://on-track-jarvis.s3.ap-south-1.amazonaws.com/483a4efb-9f9b-415d-969c-683d9727e671.svg"
              width="90%"
              src="https://on-track-jarvis.s3.ap-south-1.amazonaws.com/483a4efb-9f9b-415d-969c-683d9727e671.svg"
            />
          </v-col>
          <v-col cols="3" class="my-auto" md="2">
            <v-img
              class="mx-auto"
              lazy-src="https://on-track-jarvis.s3.ap-south-1.amazonaws.com/91e71656-c4f8-4401-8390-86b797e6c40e.svg"
              width="90%"
              src="https://on-track-jarvis.s3.ap-south-1.amazonaws.com/91e71656-c4f8-4401-8390-86b797e6c40e.svg"
            />
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <h1>
          <strong>What our customers says</strong>
        </h1>
        <div class="scroll-container py-15">
          <v-col v-for="(data, index) in reviews" :key="index">
            <v-card min-height="350px" min-width="270">
              <v-card-text>
                <p class="ma-0 pa-0 mb-4">
                  <v-icon
                    :color="
                      partner.themeColor ? partner.themeColor : 'deep-orange'
                    "
                    large
                  >
                    fas fa-quote-left
                  </v-icon>
                </p>
                <p class="para-s">{{ data.feedback }}</p></v-card-text
              >
              <v-card-actions>
                <v-list-item class="grow" style="position:absolute;bottom:0">
                  <v-list-item-avatar color="grey darken-3">
                    <v-img class="elevation-6" alt="" :src="data.image"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      <p class="ma-0 pa-0 subtitle-2">{{ data.name }}</p>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card-actions>
            </v-card>
          </v-col>
        </div>
      </v-container>

      <v-container class="pb-15">
        <v-card
          @click="applyLoan()"
          max-width="600"
          class="py-2 mx-auto"
          width="100%"
          :color="partner.themeColor ? partner.themeColor : 'deep-orange'"
          dark
        >
          <v-container class="text-center">
            <strong>Apply Loan</strong>
          </v-container>
        </v-card>
      </v-container>

      <v-container>
        <h1><strong>FAQ?</strong></h1>
        <div class="py-15">
          <v-expansion-panels>
            <v-expansion-panel v-for="(data, index) in faqsData" :key="index">
              <v-expansion-panel-header>
                <strong>{{ data.heading }}</strong>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-html="data.desc" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-container>

      <v-bottom-sheet
        max-width="700px"
        hide-overlay
        v-if="selectionData"
        v-model="hasprefilled"
      >
        <v-sheet class="text-left mb-2 pa-0">
          <div class="pa-4">
            <p class="">
              We see that you are looking for finance
              <span
                :color="partner.themeColor ? partner.themeColor : 'deep-orange'"
                >{{ selectionData.displayName }} </span
              >, Continue where you left off.
            </p>
            <div class="text-right">
              <v-btn
                @click="hasprefilled = false"
                text
                :color="partner.themeColor ? partner.themeColor : 'deep-orange'"
                class="mr-2"
                >Close
              </v-btn>
              <v-btn
                @click="$router.push('/finance/confirmdetails')"
                :color="partner.themeColor ? partner.themeColor : 'deep-orange'"
                >Continue
              </v-btn>
            </div>
          </div>
        </v-sheet>
      </v-bottom-sheet>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import LoanCalculator from "../../../../components/LoanCalculator.vue";
export default {
  components: { LoanCalculator },
  data() {
    return {
      partner: [],
      loading: false,
      partnerId: "",
      reviews: [
        {
          rating: 5.0,
          feedback:
            "The transparency shown by Ontrack during my loan application is commendable, the loan amount approved was instantly show-cased. Even at showrooms they take longer. Interest is reasonable.",
          name: "Trecel",
          image:
            "https://on-track-jarvis.s3.ap-south-1.amazonaws.com/397ee97d-e3ca-4522-ab42-4090a8624e07.png",
        },
        {
          rating: 4.0,
          feedback:
            "I’m very happy with this application, my loan got rejected with a different app, but Ontrack gave me a loan within 5 mins. My first Activa, thank you Ontrack.",
          name: "Mukheer",
          image:
            "https://on-track-jarvis.s3.ap-south-1.amazonaws.com/b1758edc-2d85-43f7-bedf-4b4041082a0b.png",
        },
        {
          rating: 5,
          feedback:
            "Never thought bike loans would be as easy as personal loans, simple form and loan approved. Had few doubts, but their FAQ section is very transparent. Didn’t even have to call.",
          name: "Sowphiya",
          image:
            "https://on-track-jarvis.s3.amazonaws.com/41883619-c132-4a52-a987-8cf362052c3b.png",
        },
      ],
      faqsData: [
        {
          heading: "What is Ontrack's New Two Wheeler Loan?",
          desc:
            "If you are in need of a new vehicle, our two wheeler loan is the way to go. We provide you with the opportunity to have the vehicle that you have always dreamed of driving. Our new two wheeler loan comes with an low interest rate and affordable EMIs to help you take your dream bike home today.",
        },
        {
          heading: `${"Why choose Ontrack's New Two Wheeler loan?"}`,
          desc:
            "<p class='para-s'>Our New Two Wheeler Loan offers the following benefits:</p><p class='para-s'>Independence – You don't have to be dependent on public transport or anyone else for all your transportation needs. You can roam the streets with the vehicle you've always wanted!</p><p class='para-s'>Hassle-free process – We provide quick processing of your loan with minimal documentation. We also provide assistance with RTO formalities and multiple repayment methods as per your convenience. Apply for Ontrack's New two wheeler loan today!</p>",
        },
        {
          heading: "How can I be eligible for Ontrack's Two Wheeler Loan?",
          desc:
            '<v-col><p class="para-s"> Salaried Professional &amp; Self-Employed over the age of 21 years and below 65 years by the end of the tenure. </p><p class="para-s"> Employment Stability of at least 3 months in case of Salaried Professionals &amp; 12 months in case of Self-Employed. </p><ul><li class="para-s"> The applicant’s age must be between 21 to 60 years. </li><li class="para-s"> The applicant must have a residential stability for a minimum of one year. </li><li class="para-s"> The applicant must have a stable employment status for at least a year. </li></ul></v-col>',
        },
        {
          heading: "Documentation",
          desc:
            '<v-col><ul><li class="para-s">Identity Proof documents –</li><p class="para-s"> Passport <br>Driving License <br>PAN Card <br>Aadhaar Card <br>Voter’s ID and <br>Recent passport-sized photographs </p><li class="para-s">Income Proof documents (if required) –</li><p class="para-s"> Latest Income Tax Returns (ITR) for self-employed individuals and <br>Last three months’ Salary Slips/Form 16/Last three months’ Bank Statements reflecting salary credits/Salary Certificate for salaried individuals </p><li class="para-s"> Last three months’ Bank Statements – mandatory </li><li class="para-s">Residence Ownership Proof – optional</li></ul></v-col>',
        },
        {
          heading: "Fee & Charges",
          desc:
            '<p class=""><p class="para-s"><p class="font-weight-bold para-s">Onroad price</p><p class="para-s">Depending on vehicle</p></p><p class="para-s"><p class="para-s font-weight-bold">EMI Tenure</p><p class="para-s">24 - 36 Months</p></p><p class="para-s"><p class="para-s font-weight-bold">Downpayment</p><p class="para-s">10 - 50%</p></p><p class="para-s"><p class="para-s font-weight-bold">Stamp Fees</p><p class="para-s">Rs 100</p></p><p class="para-s"><p class="para-s font-weight-bold">Rate of Interest</p><p class="para-s">11.99%.</p></p><p class="para-s"><p class="para-s font-weight-bold">Processing Fees</p><p class="para-s">3%</p></p><p class="para-s"><p class="para-s font-weight-bold">NACH Fees</p><p class="para-s">Rs 500</p></p></p>',
        },
        {
          heading: "Definition",
          desc:
            '<p class="para-s"><p class="mb-4 para-s"><On Road price: The On-road Price of a vehicle is the price you pay to bring the vehicle from the showroom to the road. </p><p class="mb-4 para-s">Downpayment: A down payment is an initial non-refundable payment that is paid upfront for the purchase of a high-priced item. </p><p class="mb-4 para-s">Resale amount: The price which something can achieve when sold in the future. </p><p class="mb-4 para-s">EMI: EMI or equated monthly installment, as the name suggests, is one part of the equally divided monthly outgoes to clear off an outstanding loan within a stipulated time frame. </p><p class="mb-4 para-s">ROI:Rate of Interest; the proportion of a loan that is charged as interest to the borrower, typically expressed as an annual percentage of the loan outstanding. </p><p class="mb-4 para-s">IRR: Internal rate of return is a method of calculating an investment’s rate of return. The term internal refers to the fact that the calculation excludes external factors, such as the risk-free rate, inflation, the cost of capital, or financial risk. </p><p class="mb-4 para-s">Processing Fees: Payment processing fees are the costs that dealerships and banks incur when processing payments from customers. </p><p class="mb-4 para-s">Nach charges:NACH aims at facilitating electronic Interbank High or Low Volume Debit/Credit transactions which are repetitive in nature. The banks charge a small fee from the customer for this. </p><p class="para-s">Stamp charges: Stamp duty is a tax that is levied on single property purchases or documents. A physical revenue stamp had to be attached to or impressed upon the document to show that stamp duty had been paid before the document is legally effective. </p><p class="para-s">Tenure:This stands for the agreed loan repayment time-frame between the borrower (you) and the lender (bank). </p></p>',
        },
      ],
    };
  },
  mounted() {
    this.initHomePage();
    this.partnerId = this.$route.params.partnerId;
  },
  methods: {
    applyLoan() {
      this.$router.push("/finance");
    },
    async initHomePage() {
      this.loading = true;
      try {
        const { data } = await axios.post(
          "https://glacier.on-track.in/api/finance/getpartner",
          {
            partnerId: this.$route.params.partnerId,
          }
        );
        this.partner = data;
      } finally {
        this.loading = false;
        localStorage.setItem("themeColor", this.partner.themeColor);
        localStorage.setItem(
          "source",
          JSON.stringify(this.partner.partnerId || "Organic")
        );
        var eventName = this.partner.partnerId
          ? "page-view-" + this.partner.partnerId
          : "page-view-organic";
        window.fbq("track", eventName);
        this.$gtag.event(eventName, {
          event_category: "click-event",
          event_label: "page view",
          value: 1,
        });
      }
    },
  },
};
</script>

<style>
.draw {
  background-image: linear-gradient(#dedede, #dcdcdc);
  background-size: 2px 100%;
  background-repeat: no-repeat;
  background-position: center center;
}
</style>
